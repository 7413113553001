import React, { useState, useEffect } from 'react';
import { graphql, Link} from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useCookies } from 'react-cookie';
import remarkHtml from 'remark-html'
import remarkParse from 'remark-parse'
import unified from "unified";

const Lezione = ({ data }) => {

    const lezione = data.lezione;
    const corsobase = data.corsobase;
    const contenfulId = lezione.contentful_id;
    const [email, setEmail] = useState(""); 
    const [success, setSuccess] = useState(true); 
    const [cookies, setCookie] = useCookies(['token']);
    const [contentdata, setContentdata] = useState(null);
    const [loader, setLoader] = useState(true);
    const [autologin, setAutologin] = useState(true);

    

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        checkUser(email);
    }
    const checkUser = async (username) => {
        if(username) {
            const url ='https://xzehs0z23i.execute-api.eu-west-1.amazonaws.com/dev/private/getContent';
            var data = {
                username: username,
                contenfulId: contenfulId
            };
            const response = await fetch(url, {
                method: "POST", 
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
            if (response.status === 200 ) {
                const data = await response.json();
                console.log(data);
                setContentdata(data.items.corsoBaseScritto);
                setSuccess(true);
                setCookie('token', username, { path: '/' });
            } else {
                setSuccess(false);
            }
            setLoader(false);
        }
    }

    useEffect(() => {
        if (cookies.token && autologin) {
            console.log("cookie",cookies.token);
            checkUser(cookies.token);
        } else {
            setLoader(false);
        } 
        setAutologin(false);
    },[]);

    const convert =  (data) => {
        const html =  unified().use(remarkParse).use(remarkHtml,{sanitize:false}).processSync(data);
        return html.toString();
    }

    return (
        <Layout>
            <SEO title={lezione.titolo}/>
            <div className="row">
                <div className="col-12 text-center">
                <h1 className="pagetitle">{corsobase.titolo}</h1>
                </div>
            </div>
            {contentdata ? (
            <div className="section section--notop blog">
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2">
                        {/* <img alt={post.titolo} className="blog__interna" src={post.copertina.file.url} /> */}
                        <h1 className="pagetitle">{contentdata.titolo}</h1>
                        <div className="blog__abstract">{contentdata.sottotitolo}</div>
                        <section>
                            {contentdata.intro && <div className="section__text blog__text" dangerouslySetInnerHTML={{__html: convert(contentdata.intro)}} /> }
                            {contentdata.titoloSezione1 && <h2 className="pagetitle">1. {contentdata.titoloSezione1}</h2>}
                            {contentdata.testoSezione1 && <div className="section__text blog__text" dangerouslySetInnerHTML={{__html: convert(contentdata.testoSezione1)}} /> }
                            {contentdata.titoloSezione2 && <h2 className="pagetitle">2. {contentdata.titoloSezione2}</h2>}
                            {contentdata.testoSezione2 && <div className="section__text blog__text" dangerouslySetInnerHTML={{__html:  convert(contentdata.testoSezione2)}} /> }
                            {contentdata.titoloSezione3 && <h2 className="pagetitle">3. {contentdata.titoloSezione3}</h2>}
                            {contentdata.testoSezione3 && <div className="section__text blog__text" dangerouslySetInnerHTML={{__html:  convert(contentdata.testoSezione3)}} /> }
                            {contentdata.titoloSezione4 && <h2 className="pagetitle">4. {contentdata.titoloSezione4}</h2>}
                            {contentdata.testoSezione4 && <div className="section__text blog__text" dangerouslySetInnerHTML={{__html:  convert(contentdata.testoSezione4)}} /> }
                        </section>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-12 text-center">
                        {/* <a href="/blog/" onClick={goTo} className="btn-action">Torna indietro</a> */}
                        <Link to="/corsi/corso-base-teoria/" className="btn-action">Torna all'indice</Link>
                    </div>
                </div>
                
            </div>
            ): (
                <div className="section form"> 
                    {loader ? (
                        <div>&#160;</div>
                    ): (
                        <div className="row">
                            <div className="col-md-12 col-lg-6 offset-lg-3">
                                <h1 className="pagetitle">Accedi alla lezione</h1>
                                <div className="field__group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" required  name="email" onChange={e => setEmail(e.target.value)}/>
                                </div>
                                <div className="text-center">
                                    <input type="submit" className="btn-action btn-action--white" onClick={handleSubmit} value="Entra"/>
                                </div>
                                {!success &&
                                    <div>utente non valido</div>
                                }
                            </div>
                        </div>
                    )}
              </div>
            )}
        </Layout>
    )
}

export const query = graphql`
    query LezioneQuery($contentful_id: String!, $locale: String!) {
        lezione: contentfulCorsoBaseScritto(contentful_id: {eq: $contentful_id}, node_locale: {eq: $locale}){
            node_locale
            titolo
            contentful_id
        }  
        corsobase : contentfulCorsoBaseScrittoTestata(contentful_id: {eq: "1rEkoRywkIXeT9HBRtuOQL"}, node_locale: {eq: $locale}) {
            titolo
        }
    }
`;

export default Lezione;
